<template>
  <sign-block
    v-if="display"
    title-text="教育经历"
    :request="request"
    :titleMenus="readOnly ? [] : [{key: 'add', label: '新增'}]"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :pageFormSubmit="pageFormSubmit"
    :dataFilefuns="dataFilefuns"
    :pageDelData="pageDelData"
    table-size="large"
    :tableActions="tableActions"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData"
    :dealData="dealData"
    :initSearchData="{workerId: this.workerId}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-block>
</template>

<script>
import {
  applyFormSubmit,
  applyDel
} from './apply'
import {
  workerEduRequest as request
} from '@/api'

export default {
  props: {
    workerId: {
      type: [String, Number]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    display: {
      type: Boolean,
      default: true
    },
    historyDate: {
      type: Date,
      default: null
    }
  },
  created () {
    if (!this.display) {
      this.loadData()
    }
  },
  methods: {
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      return {hisData, newData}
    },
    async pageDelData (parm) {
      await applyDel({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        applicableType: 'worker_edu'
      })
    },
    async pageFormSubmit (parm) {
      await applyFormSubmit({
        vm: this,
        id: parm.vm.chooseData && parm.vm.chooseData.id ? parm.vm.chooseData.id : undefined,
        sourceData: parm.vm.chooseData,
        userId: this.$store.getters.userInfo.id,
        data: parm.data,
        dealApplyData: this.dealApplyData,
        applicableType: 'worker_edu'
      })
    },
    dealData (data) {
      return !this.historyDate ? data : data.filter(v => new Date(v.endDate) <= this.historyDate)
    },
    initAddFormData () {
      return {
        workerId: this.workerId
      }
    },
    async loadData () {
      let parm = {
        workerId: this.workerId
      }
      let data = await request.get(parm)

      if (!data.length) {
        this.$emit('completeness', 0)
      } else {
        const keys = this.formParmsAdd.map(v => v.key)
        const num = data.reduce((a, item) => {
          return a + keys.reduce((c, key) => {
            if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
              return c + 1
            } else {
              return c
            }
          }, 0)
        }, 0)
        this.$emit('completeness', num / (data.length * keys.length))
      }
      this.$emit('dataOk', data && data.length > 0)
      return data
    }
  },
  computed: {
    dataFilefuns () {
      return {
        get: this.$authFunProxy['worker.worker_edu-getDataFile'],
        upload: !this.readOnly && this.$authFunProxy['worker.worker_edu-updateDataFile'],
        down: !this.readOnly && this.$authFunProxy['worker.worker_edu-downDataFile'],
        del: !this.readOnly && this.$authFunProxy['worker.worker_edu-delDataFile'],
        mkdir: !this.readOnly && this.$authFunProxy['worker.worker_edu-mkdirDataFile']
      }
    },
    tableActions () {
      return this.readOnly ? [{key: 'data_file', label: '扫描件'}] : [{key: 'data_file', label: '扫描件'}, {key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '学历',
          selectDatas: [{key: '小学', label: '小学'}, {key: '初中', label: '初中'}, {key: '高中', label: '高中'}, {key: '中专', label: '中专'}, {key: '大专', label: '大专'}, {key: '本科', label: '本科'}, {key: '硕士研究生', label: '硕士研究生'}, {key: '博士研究生', label: '博士研究生'}],
          key: 'edu',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '毕业院校',
          key: 'school'
        },
        {
          type: 'input',
          label: '学校编码',
          key: 'schoolCode'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'datePicker',
          label: '入学时间',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '毕业时间',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '学制',
          key: 'xz'
        },
        {
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'select',
          label: '教育形式',
          selectDatas: [{key: '全日制', label: '全日制'}, {key: '在职教育', label: '在职教育'}],
          key: 'jyxs'
        },
        {
          type: 'select',
          label: '学历类型',
          selectDatas: [{key: '第一学历', label: '第一学历'}, {key: '最高学历', label: '最高学历'}, {key: '普通学历', label: '普通学历'}, {key: '最高学历兼第一学历', label: '最高学历兼第一学历'}],
          key: 'type'
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '员工工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '学历',
          field: 'edu',
          sort: true
        },
        {
          title: '毕业院校',
          field: 'school',
          sort: true
        },
        {
          title: '学校编码',
          field: 'schoolCode',
          sort: true
        },
        {
          title: '专业',
          field: 'pro',
          sort: true
        },
        {
          title: '入学时间',
          field: 'startDate',
          sort: true,
          width: 130,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
          }
        },
        {
          title: '毕业时间',
          field: 'endDate',
          width: 130,
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
          }
        },
        {
          title: '学制',
          field: 'xz',
          sort: true
        },
        {
          title: '证书编号',
          field: 'zsCode',
          sort: true
        },
        {
          title: '教育形式',
          field: 'jyxs',
          sort: true
        },
        {
          title: '学历类型',
          field: 'type',
          sort: true
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
